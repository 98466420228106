.acordion-content {
    overflow: hidden;
}

.acordion-content.entering {
    animation: open 0.2s forwards;
}

.acordion-content.exiting {
    animation: open 0.2s forwards reverse;
}

.arrow.entering {
    animation: rotate 0.2s forwards;
    
}

.arrow.entered {
    transform: rotate(180deg);
}

.arrow.exiting {
    animation: rotate-back 0.2s forwards;
}


@keyframes open {
    from {
        max-height: 0;
    }
    to {
        max-height: 15em;
    }
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(180deg);
    }
}

@keyframes rotate-back {
    from {
        transform: rotate(180deg);
    }
    to {
        transform: rotate(0deg);
    }
}