@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "HelveticLT" !important;
}

@font-face {
  font-family: "GothamMd";
  src: url(./assets/fonts/GothamMedium.ttf);
  font-weight: normal;
}

@font-face {
  font-family: "Gotham";
  src: url(./assets/fonts/Gotham-Black.ttf);
  font-weight: bolder;
}
@font-face {
  font-family: "Gotham";
  src: url(./assets/fonts/GothamBold.ttf);
  font-weight: bold;
}

@font-face {
  font-family: "FiraGo";
  src: url(./assets/fonts/FiraGO-Regular.ttf);
  font-weight: normal;
}
/* @font-face {
  font-family: "FiraGo";
  src: url(./assets/fonts/FiraGO-SemiBold.ttf);
  font-weight: bold;
} */
/* @font-face {
  font-family: "FiraGo";
  src: url(./assets/fonts/FiraGO-ExtraBold.ttf);
  font-weight: bolder;
} */
@font-face {
  font-family: "HelveticLT";
  src: url(./assets/fonts/Helvetica.ttf);
}
@font-face {
  font-family: "HelveticMd";
  src: url(./assets/fonts/HelveticaNeueLTGEOW82-65Md.ttf);
}

@font-face {
  font-family: "Tahoma";
  src: url(./assets/fonts/TahomaRegularFont.ttf);
}

@font-face {
  font-family: "Mtavruli";
  src: url(./assets/fonts/bpg_nino_mtavruli_bold.ttf);
}

@font-face {
  font-family: "TBCContracticaCAPS";
  src: url(./assets/fonts/TBCContracticaCAPS-Bold.ttf);
}
.font-fira {
  font-family: "FiraGo";
}

.font-gotham {
  font-family: "Gotham";
}
